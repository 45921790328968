.footer {
    position: relative;
    background-image: url('../../../images/contact-page-image.png');
    background-size: cover;
    background-position: center;
    height: 520px;
    color: white;
  }
  
  .footer-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: rgba(0, 0, 0, 0.5); Adjust the overlay color and opacity as needed */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .get-in-touch-heading {
    margin-top: 5rem;
    margin-bottom: auto;
    font-size: 50px;
    font-weight:600;
  }
  
  .get-in-touch-text {
    font-size: 18px;
    margin-top: 3px;
    margin-left: 3px;
    margin-bottom: 20px;
  }

  .overlay-bottom-text {
    font-size: 14px;
    margin-top: 4rem;
    
  }

  .linkedin-logo {
    margin-top: 10rem;
    width: 4rem;
    height: 4rem;
  }
  
  .social-icon {
    color: white;
    margin: 0 10px;
  }
  