input::placeholder {
    color: #b8b4b4 !important;
}

textarea::placeholder {
    color: #b8b4b4 !important;
}

.submit-button {
    background-color: #0E3868 !important;
    border-color: #0E3868 !important;
}

input {
    border-color: #0E3868 !important;
}

textarea {
    border-color: #0E3868 !important;
}
    

